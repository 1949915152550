import React from 'react';

// MUI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { dataShopItem } from '@common/data/ShopItem';
import { ReactComponent as SectionHeaderIcon } from '@common/assets/section_header.svg';
import { ItemDetailsSkeleton } from './Skeleton';
import { dataOrder } from '@common/data/Order';
import { ShopItemMasterStock } from '@common/data/ShopItemMaster';
import { ShopItemMaker } from '@common/data/ShopItemMaker';

type ItemDialogProps = {
  readonly open: boolean;
  readonly itemId: string;
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
};
export const ItemDialog = ({ open, itemId, callbackOk, callbackCancel }: ItemDialogProps) => {
  return (
    <Dialog open={open} onClose={callbackCancel} fullWidth maxWidth="xl">
      <DialogContent>
        <React.Suspense fallback={<ItemDetailsSkeleton></ItemDetailsSkeleton>}>
          <ItemDetails itemId={itemId} />
        </React.Suspense>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <DialogButton itemId={itemId} callbackOk={callbackOk} callbackCancel={callbackCancel} />
      </DialogActions>
    </Dialog>
  );
};

type DialogButtonProps = {
  readonly itemId: string;
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
};
const DialogButton = ({ itemId, callbackOk, callbackCancel }: DialogButtonProps) => {
  const item = dataShopItem.itemMaster.useShopItemMaster(itemId);
  const availableByStock = dataOrder.cart.useItemAvailableCountByStock(itemId);
  const availableByCartSize = dataOrder.cart.useItemAvailableCountByCartSize(itemId);
  const availableByCartWeight = dataOrder.cart.useItemAvailableCountByCartWeight(itemId);
  const maxCartItemsAddableByCount = dataOrder.cart.useItemAvailableCountByCount(itemId);

  if (!item) return <>Invalid Item ID</>; // never

  if (availableByStock <= 0)
    return (
      <div
        className="w-2/3 py-2 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-4"
        onClick={callbackCancel}
      >
        戻る （売り切れ）
      </div>
    );

  if (availableByCartSize <= 0)
    return (
      <div
        className="w-2/3 py-2 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-4"
        onClick={callbackCancel}
      >
        戻る （配達容量が上限に達しています）
      </div>
    );

  if (availableByCartWeight <= 0)
    return (
      <div
        className="w-2/3 py-2 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-4"
        onClick={callbackCancel}
      >
        戻る （配達重量が上限に達しています）
      </div>
    );

  if (maxCartItemsAddableByCount <= 0)
    return (
      <div
        className="w-2/3 py-2 rounded-full cursor-pointer
          text-center text-2xl font-bold text-primary border-primary border-4"
        onClick={callbackCancel}
      >
        戻る （配達総数が上限に達しています）
      </div>
    );

  return (
    <div className="w-full h-fit flex flex-row">
      <div
        className="w-1/3 py-2 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-4 mr-4"
        onClick={callbackCancel}
      >
        戻る
      </div>
      <div
        className="w-2/3 py-3 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-white bg-primary"
        onClick={callbackOk}
      >
        カートに追加
      </div>
    </div>
  );
};

const ItemDetails = ({ itemId }: { itemId: string }) => {
  const item = dataShopItem.itemMaster.useShopItemMaster(itemId);
  if (!item) return <>Invalid Item ID</>; // never
  const maker = item?.makerId ? dataShopItem.maker.useShopItemMaker(item.makerId) : undefined;

  return (
    <div className="w-full flex flex-col">
      <ItemInfo item={item} />
      {maker && <MakerInfo maker={maker} />}
    </div>
  );
};

const ItemInfo = ({ item }: { item: ShopItemMasterStock }) => {
  return (
    <>
      <div className="flex flex-row">
        <div className="w-1/2 pr-2">
          <img
            alt="商品画像"
            src={item.image[0]}
            className="w-full h-full object-cover rounded-md"
          />
        </div>
        <div className="w-1/2 flex flex-col">
          <div className="text-2xl font-bold">{item.name}</div>
          <div className="mt-2">
            <span className="font-bold">{item.price.toLocaleString()}</span>
            <span className="text-xs"> 円（税込）</span>
          </div>
          <div className="mt-2 font-bold">{item.makerName}</div>
          {item.copy && <div className="mt-2 whitespace-pre-wrap">{item.copy}</div>}
        </div>
      </div>
      {item.description && (
        <div>
          <hr className="my-4" />
          <div className="flex flex-row items-center">
            <SectionHeaderIcon className="w-4 h-4 mr-2" />
            <span className="font-bold">商品紹介</span>
          </div>
          <div className="mt-2">
            {item.image.length >= 2 && (
              <img
                alt="商品画像2"
                src={item.image[1]}
                className="mr-2 w-1/3 float-left rounded-md"
              />
            )}
            <span className="whitespace-pre-wrap">{item.description}</span>
          </div>
        </div>
      )}
      {(item.allergen || item.expiration || item.bestByDate) && (
        <div>
          <hr className="my-4" />

          <div className="flex ml-4">
            {item.allergen && (
              <div className="flex-auto whitespace-nowrap">
                <span className="mr-2 text-sm font-bold">【特定原材料】</span>
                <span className="text-sm">{item.allergen}</span>
              </div>
            )}
            {item.expiration && (
              <div className="flex-auto whitespace-nowrap">
                <span className="mr-2 text-sm font-bold">【消費期限】</span>
                <span className="text-sm">{item.expiration}</span>
              </div>
            )}
            {item.bestByDate && (
              <div className="flex-auto whitespace-nowrap">
                <span className="mr-2 text-sm font-bold">【消味期限】</span>
                <span className="text-sm">{item.bestByDate}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const MakerInfo = ({ maker }: { maker: ShopItemMaker }) => {
  return (
    <div>
      <hr className="my-4" />

      <div className="flex flex-row items-center">
        <SectionHeaderIcon className="w-4 h-4 mr-2" />
        <span className="font-bold">メーカー／ブランド</span>
      </div>
      <div className="mt-2 flex flex-row">
        {maker.image && maker.image[0] !== '' && (
          <div className="w-1/3">
            <img alt="店舗画像" src={maker.image[0]} className="mr-2 w-full rounded-md" />
          </div>
        )}
        <div className="grow flex flex-col pl-2">
          <div className="font-bold">{maker.makerName}</div>
          {maker.businessHours && (
            <div className="flex text-sm">
              <div className="w-16">営業時間</div>
              {maker.businessHours}
            </div>
          )}
          {maker.holiday && (
            <div className="flex text-sm">
              <div className="w-16">定休日</div>
              {maker.holiday}
            </div>
          )}
          {maker.phone && (
            <div className="flex text-sm">
              <div className="w-16">電話番号</div>
              {maker.phone}
            </div>
          )}
          {maker.postalCode && maker.address && (
            <div className="text-sm">
              〒{maker.postalCode} {maker.address}
            </div>
          )}
          {maker.url && (
            <div className="text-sm">
              <a href={maker.url}>{maker.url}</a>
            </div>
          )}
        </div>
      </div>
      {maker.description && <div className="mt-2">{maker.description}</div>}
    </div>
  );
};
