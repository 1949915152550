import { Step, StepLabel, Stepper } from '@mui/material';

export function OrderStepper({ activeStep }: { activeStep: number }) {
  return (
    <Stepper activeStep={activeStep}>
      <Step key="step1">
        <StepLabel>
          <span>カート内容</span>
        </StepLabel>
      </Step>
      <Step key="step2">
        <StepLabel>
          <span>携帯電話番号入力</span>
        </StepLabel>
      </Step>
      <Step key="step3">
        <StepLabel>
          <span>ご注文内容確認</span>
        </StepLabel>
      </Step>
      <Step key="step4">
        <StepLabel>
          <span>お支払い</span>
        </StepLabel>
      </Step>
      <Step key="step5">
        <StepLabel>
          <span>注文完了</span>
        </StepLabel>
      </Step>
    </Stepper>
  );
}
