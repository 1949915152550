import { Paper } from '@mui/material';
import { userActions } from '@common/data/User';

export const UserAdminInfo = () => {
  const user = userActions.useGetUser();
  const cognitoUserInfo = userActions.useCognitoUserInfo();
  return (
    <>
      <Paper
        sx={{
          width: 'auto',
          padding: 2,
          color: 'text.secondary',
          typography: 'body2',
          position: 'fixed',
          bottom: 10,
          right: 10,
        }}
      >
        <ol>
          <li>email: {user.email} </li>
          <li>userId: {user.userId} </li>
          <li>groups: {cognitoUserInfo.groups?.join(',')} </li>
        </ol>
      </Paper>
    </>
  );
};
