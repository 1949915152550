import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
// MUI Icon
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Home from '@mui/icons-material/Home';
// import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import InfoIcon from '@mui/icons-material/Info';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

//Version
import { MyVersion } from './MyVersion';

type DialogSignOutProps = {
  readonly open: boolean;
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
};
export const DialogSignOut = ({ open, callbackOk, callbackCancel }: DialogSignOutProps) => {
  return (
    <Dialog open={open} onClose={callbackCancel}>
      <DialogTitle>ログアウトしますか？</DialogTitle>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={callbackOk} autoFocus>
          OK
        </Button>
        <Button variant="outlined" onClick={callbackCancel}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function Menu() {
  const navigate = useNavigate();
  const [openSignOutDialog, setOpenSignOutDialog] = useState(false);

  const signOutFunc = async () => {
    await Auth.signOut();
    navigate('/');
    navigate(0);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List>
        <ListItem>
          <ListItemButton onClick={() => navigate('/account')}>
            <ListItemIcon>
              <AccountBoxRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="アカウント情報" />
            <NavigateNextRoundedIcon />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => navigate('/shopSelect')}>
            <ListItemIcon>
              <StoreRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="店舗選択" />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => setOpenSignOutDialog(true)}>
            <ListItemIcon>
              <LogoutRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <DialogSignOut
          open={openSignOutDialog}
          callbackOk={signOutFunc}
          callbackCancel={() => setOpenSignOutDialog(false)}
        />

        <ListItem>
          <ListItemButton onClick={() => navigate('/OrdersDeliverySpotDetail')}>
            <ListItemIcon>
              <LocalShipping fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="配達状況表示モード" />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => navigate('/', { replace: true })}>
            <ListItemIcon>
              <Home fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="データ更新 ＆ トップページへ移動" />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <InfoIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary={MyVersion.Version} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
