import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { dataShop } from '@common/data/Shop';
import { useClickActionButtonSound } from '@common/data/SoundEffect';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const SelectSpotDialog = ({
  isOpen,
  closeCallback,
  isReload = true,
}: {
  isOpen: boolean;
  closeCallback: () => void;
  isReload?: boolean;
}) => {
  const shop = dataShop.useShopSelected();
  const [latestSelectedId, setLatestSelectedId] = dataDeliveryTo.useSpotSelectedIdState();
  // const deliveryTimeRefresh = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();
  // const [isOpenBackDrop, setIsOpenBackDrop] = useState(false);

  const playClickActionButtonSound = useClickActionButtonSound();

  const changeSpotCallback = async (spotId: string) => {
    playClickActionButtonSound();
    setLatestSelectedId(spotId);
    // TODO: できれば、backDropで制御したいが配送時間がカオスなので一旦リロードで対応
    if (isReload) {
      window.location.reload();
    } else {
      closeCallback();
    }
  };

  const selectStyle = (spotId: string) => (latestSelectedId === spotId ? 'bg-primary' : 'border');

  return (
    <Dialog open={isOpen}>
      {/* <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpenBackDrop}>
        <CircularProgress />
      </Backdrop> */}
      <DialogTitle>配送先の選択</DialogTitle>
      <DialogContent>
        <div className="mb-2 ml-2">ご希望の配送先を選択してください</div>
        <div key={shop.shopId} className="mb-4">
          <p className="text-lg mb-1">{shop.shopName}</p>
          {shop.spots.map((spot) => (
            <div
              key={spot.spotId}
              className={`mt-2 flex gap-2 items-center cursor-pointer p-2 rounded-md ${selectStyle(
                spot.spotId
              )}`}
              onClick={() => {
                changeSpotCallback(spot.spotId);
              }}
            >
              {spot.image.length !== 0 ? (
                <img
                  alt="配達場所画像"
                  src={spot.image[0]}
                  className="w-1/2 h-1/3 object-contain rounded-t-md"
                />
              ) : (
                <div className="w-1/2 h-1/3 text-center">画像なし</div>
              )}

              <p>{spot.spotName}</p>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeCallback} autoFocus>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
