import { UserForm } from './UserForm';
import { Account } from '@common/components/Account';
import { UserAdminInfo } from '@common/components/UserAdminInfo';
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export const UserInfo = () => {
  const navigate = useNavigate();

  return (
    <Suspense
      fallback={
        <div className="w-full h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      <UserForm title="担当者情報" btnText="更新" />
      <Account />
      <UserAdminInfo />
      <div className="flex justify-center">
        <div
          className="w-1/3 mt-8 rounded-full text-center cursor-pointer text-2xl font-bold text-primary border-primary border-4 mr-4"
          onClick={() => navigate('/AdministratorMenu')}
        >
          管理者メニューに戻る
        </div>
      </div>
    </Suspense>
  );
};
