import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { dataOrder } from '@common/data/Order';
import { api } from '@common/data/Api';
// MUI
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useClickActionButtonSound, useClickNavigationButtonSound } from '@common/data/SoundEffect';
import { CartItemsSkeleton, CartPriceSkeleton } from './Skeleton';
import { DialogOk } from '@common/components/Dialog';
import { userActions } from '@common/data/User';

import { OrderStepper } from './OrderStepper';

export const OrderConfirm = () => {
  const phoneNumber = dataOrder.phoneNumber.useOrderPhoneNumberState(); // ex. '09012345678';
  const deliveryTime = dataOrder.deliveryTime.useDeliveryTimeState();
  const selectedDate = dataOrder.cart.useCartDate();
  const selectedSpot = dataOrder.deliveryTo.useSpotSelected();

  const [openProgress, setOpenProgress] = useState(false);
  const navigate = useNavigate();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();
  const playClickActionButtonSound = useClickActionButtonSound();
  const placeOrderSkippingPayment = api.usePlaceOrderSkippingPayment(true);
  const gotoPayment = api.useGotoPayment(true);
  const [openNavigateToCart, setOpenNavigateToCart] = useState(false);
  const [openNavigateToSelectTime, setOpenNavigateToSelectTime] = useState(false);
  const [openNavigateToDeliverySpot, setOpenNavigateToDeliverySpot] = useState(false);
  const [openUnKonwnError, setopenUnKonwnError] = useState(false);
  const cognitoUserInfo = userActions.useCognitoUserInfo();
  const spotRefresh = dataOrder.deliveryTo.useSpotRefresher();
  const refreshCap = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();

  function goError(msg?: { reason: string }) {
    if (msg) {
      console.log(msg.reason);
      if (msg.reason === 'CAPACITY_EXPIRED') {
        setOpenNavigateToSelectTime(true);
      } else if (msg.reason === 'OUT_OF_CAPACITY') {
        setOpenNavigateToSelectTime(true);
      } else if (msg.reason === 'OUT_OF_STOCK') {
        setOpenNavigateToCart(true);
      } else if (msg.reason === 'INVALID_DELIVERY_SPOT_ID') {
        setOpenNavigateToDeliverySpot(true);
      } else if (msg.reason === 'INVALID_ITEM_ID') {
        setOpenNavigateToCart(true);
      } else {
        setopenUnKonwnError(true);
      }
      console.log('order failed: ' + msg.reason);
    }
    setOpenProgress(false);
  }

  const skipPayment = async () => {
    playClickNavigationButtonSound();
    setOpenProgress(true);
    const msg = await placeOrderSkippingPayment();
    if (msg && msg?.status === 'ok') {
      console.log(msg);
      //setOpenProgress(false);
      navigate('/order/completed/' + msg.order.orderId);
    } else {
      goError(msg);
    }
  };

  const goPayment = async () => {
    playClickNavigationButtonSound();
    setOpenProgress(true);
    const msg = await gotoPayment();
    goError(msg);
  };

  return (
    <div className="w-screen h-screen flex flex-col">
      <section className="p-6 overflow-y-auto" style={{ backgroundColor: 'rgb(251,240,221)' }}>
        <div className="w-1/2 mx-auto mb-2">
          <OrderStepper activeStep={2} />
        </div>
        <div className="mb-2 text-4xl font-bold text-center">ご注文内容の確認</div>
        <div className="mb-4 text-md text-center">
          以下の内容でご注文を受け付けます。これ以降お支払いの手続きとなり、ご注文内容の変更・キャンセルはできません。
        </div>

        <div className="flex flex-row">
          <div className="flex flex-col overflow-y-auto pr-6">
            <div className="text-xl font-bold mb-2">ご注文商品</div>
            <React.Suspense fallback={<CartItemsSkeleton></CartItemsSkeleton>}>
              <CartItems />
            </React.Suspense>
          </div>
          <div className="flex flex-col w-2/5">
            <div className="w-full flex flex-col justify-center">
              <div className="text-xl font-bold mb-2">お届け予定時刻</div>
              <div className="text-2xl rounded-xl p-4" style={{ backgroundColor: 'white' }}>
                <div className="flex items-center">
                  <div className="grow">
                    <span className="mr-2">
                      {selectedDate.getMonth() + 1 + '月' + selectedDate.getDate() + '日 '}
                    </span>
                    <span className="font-bold">{deliveryTime.toStringTime('-')}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col justify-center mt-2">
              <div className="text-xl font-bold mb-2">お届け先</div>
              <div
                className="rounded-xl flex flex-row text-xl items-center justify-start p-4 gap-2"
                style={{ backgroundColor: 'white' }}
              >
                {selectedSpot.image.length !== 0 ? (
                  <img
                    alt="配送先画像"
                    src={selectedSpot.image[0]}
                    className="object-cover rounded-l-xl h-16"
                  />
                ) : (
                  <p className="">配送先画像なし</p>
                )}
                <p className="font-bold">{selectedSpot.spotName}</p>
              </div>
            </div>

            <div className="w-full flex flex-col justify-center mt-2">
              <div className="text-xl font-bold mb-2">お客様携帯電話番号</div>
              <div className="text-2xl rounded-xl p-4" style={{ backgroundColor: 'white' }}>
                {phoneNumber}
              </div>
            </div>

            <div className="w-full flex flex-col justify-center mt-2 mb-24">
              <div className="text-xl font-bold">お支払い</div>
              <div className="text-warning text-sm mb-2">
                <span className="font-bold">PayPay残高払い・後払い</span>
                <span>をご利用いただけます。</span>
              </div>
              <React.Suspense fallback={<CartPriceSkeleton></CartPriceSkeleton>}>
                <CartPrice />
              </React.Suspense>
            </div>
          </div>
        </div>
      </section>
      <footer className="fixed bottom-0 left-0 w-full bg-white p-2">
        <div className="grid grid-cols-3 gap-4 items-end">
          <>
            <div>
              <div
                className="p-3 rounded-full cursor-pointer
          text-center text-2xl font-bold text-primary border-primary border-4"
                onClick={() => {
                  playClickNavigationButtonSound();
                  navigate('/order/phoneNumber');
                }}
              >
                戻る
              </div>
            </div>
            <div>
              {cognitoUserInfo.groups.includes('Developer') ? (
                <div
                  className="p-3 w-200 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-warning border-warning border-4"
                  onClick={skipPayment}
                >
                  支払スキップ
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div>
                当サービスの
                <span
                  className="cursor-pointer font-bold text-primary"
                  onClick={() => {
                    navigate('/agreement');
                    playClickActionButtonSound();
                  }}
                >
                  利用規約
                </span>
                に同意して
              </div>
              <div
                className="p-4 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-white bg-primary"
                onClick={goPayment}
              >
                お支払いへ進む
              </div>
            </div>
          </>
        </div>
      </footer>

      <DialogOk
        open={openNavigateToCart}
        title={'選択された商品を確保できませんでした'}
        message={'カートの内容をご確認ください'}
        callbackOk={() => navigate('/order/cart')}
      />
      <DialogOk
        open={openNavigateToSelectTime}
        title={'選択されたお届け時間は利用できません'}
        message={'選択されたお届け時間の枠を確保できませんでした。お届け時間を再度選択してください'}
        callbackOk={() => {
          refreshCap();
          navigate('/order/cart');
        }}
      />
      <DialogOk
        open={openNavigateToDeliverySpot}
        title="選択されたお届け先は利用できません"
        message="お届け先を再度選択してください"
        callbackOk={() => {
          spotRefresh();
          navigate('/order/cart');
        }}
      />
      <DialogOk
        open={openUnKonwnError}
        title="原因不明のエラーが発生しました"
        message="注文できませんでした"
        callbackOk={() => {
          spotRefresh();
          navigate('/order/cart');
        }}
      />
      <Backdrop open={openProgress}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

const CartPrice = () => {
  const cartTotalCount = dataOrder.cart.useCartTotalCount();
  const cartPrice = dataOrder.cart.useCartPrice();
  const deliveryFee = dataOrder.deliveryFee.useDeliveryFee();
  const discount = dataOrder.discount.useDiscount();
  const totalPrice = cartPrice + deliveryFee + discount;

  return (
    <div className="rounded-xl px-4 py-3" style={{ backgroundColor: 'white' }}>
      <div className="w-full flex flex-row justify-between">
        <div className="">商品合計（{cartTotalCount}点）</div>
        <div className="">
          <span className="">{cartPrice.toLocaleString()}</span>
          <span className="text-lg"> 円</span>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between">
        <div className="">送料</div>
        <div className="">
          <span className="">{deliveryFee.toLocaleString()}</span>
          <span className="text-lg"> 円</span>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between">
        <div className="">割引</div>
        <div className="">
          <span className="">{discount.toLocaleString()}</span>
          <span className="text-lg"> 円</span>
        </div>
      </div>
      <div className="w-full mt-1 pt-2 flex flex-row justify-between border-t-2 border-black">
        <div className="">お支払い合計金額：</div>
        <div className="">
          <span className="text-2xl font-bold">{totalPrice.toLocaleString()}</span>
          <span className="text-lg font-bold"> 円</span>
        </div>
      </div>
    </div>
  );
};

const CartItems = () => {
  const cartItems = dataOrder.cart.useCartItems();

  return (
    <div className="grid grid-cols-2 gap-4">
      {cartItems.map((item, index) => (
        <div key={item.itemId} className="rounded-xl bg-white">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <img
                alt="商品画像"
                src={item.image[0]}
                className="w-36 h-24 m-3 object-cover rounded-xl"
              />
            </div>
            <div className="grow flex flex-col m-2 mt-4">
              <div className="text-base line-clamp-1">{item.makerName}</div>
              <div className="font-bold line-clamp-2 mb-4">{item.name}</div>

              <div className="">
                <span className="text-sm">数量:</span>
                <span className="mr-2">{item.count}</span>
                <span className="text-sm"> ¥</span>
                <span className="">{item.price.toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
