import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClickNavigationButtonSound } from '@common/data/SoundEffect';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CONST } from '@common/global';

export const Agreement = () => {
  const [html, setHtml] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();

  // S3からhtmlファイルを取得する
  useEffect(() => {
    if (!CONST.CLOUDFRONT_URL) {
      console.error('CLOUDFRONT_URLがenvに設定されていません。');
      setIsError(true);
      return;
    }
    const src = CONST.CLOUDFRONT_URL + 'agreements/TermsOfUse.html';
    fetch(src)
      .then((res) => res.text())
      .then((text) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        setHtml(doc.body.innerHTML);
      });
  }, []);

  function HtmlData() {
    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></Box>
    );
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box className="mb-20 p-2">
          <Typography variant="h5" className="text-center py-4">
            利用規約
          </Typography>

          <Typography component={'div'} className="pt-2">
            {isError ? '規約の取得に失敗しました。' : <HtmlData />}
          </Typography>
        </Box>
      </Container>
      <footer className="fixed bottom-0 left-0 w-full bg-white">
        <div
          className="m-4 p-2 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-primary border-primary border-4"
          onClick={() => {
            playClickNavigationButtonSound();
            navigate('/order/confirm');
          }}
        >
          戻る
        </div>
      </footer>
    </>
  );
};
