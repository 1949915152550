import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dataOrder } from '@common/data/Order';

export const OrderClear = () => {
  React.useState(false);
  const navigate = useNavigate();

  // NOTE: clearCartの内部でdeliveryTimeのsnapshotを使用しているが
  // APIのレスポンスが返ってくる前に発火するとエラーになるため、deliveryTimeとcartを取得しておく
  dataOrder.deliveryTime.useDeliveryTimeOptions();
  dataOrder.cart.useCartItems();
  const clearCart = dataOrder.cart.useClearCart();

  useEffect(() => {
    const clear = async () => {
      await clearCart();
      setTimeout(() => {
        navigate('/');
      }, 3000);
    };
    clear();
  }, []);

  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="w-full h-4/5 flex flex-col items-center justify-center">
        <div className="text-6xl text-center">一定時間操作がありませんでした。</div>
        <div className="mt-8 text-xl text-center">自動的にトップページへ戻ります。</div>
      </div>
    </div>
  );
};
