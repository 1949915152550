// import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dataOrder } from '@common/data/Order';
// MUI
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';
import React from 'react';

import { useClickActionButtonSound, useClickNavigationButtonSound } from '@common/data/SoundEffect';
import { dataShopItemStock } from '@common/data/ShopItemStock';
import { dataShopItemMaster } from '@common/data/ShopItemMaster';

export const Cart = () => {
  const navigate = useNavigate();
  const cartTotalCount = dataOrder.cart.useCartTotalCount();
  const cartReady = dataOrder.cart.useIsCartReadyToBuy();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();
  const refreshStock = dataShopItemStock.useShopItemStockRefresher();
  const refreshCap = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();
  const refreshItems = dataShopItemMaster.useShopItemRefresher();

  return cartTotalCount ? (
    !cartReady ? (
      <div className="w-full h-full flex flex-col">
        <CartContent />
        <div
          className="w-1/5 mb-2 py-2 rounded-full 
          text-center text-2xl font-bold text-white bg-gray-300
          bottom-0 right-0 fixed"
        >
          <Badge badgeContent={cartTotalCount} color="warning">
            <ShoppingCartRoundedIcon className="" />
          </Badge>
          <span>注文へすすむ</span>
        </div>
      </div>
    ) : (
      <div className="w-full h-full flex flex-col">
        <CartContent />
        <React.Suspense>
          <div
            className="w-1/5 mb-2 py-2 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-white bg-primary
        bottom-0 right-0 fixed"
            onClick={() => {
              playClickNavigationButtonSound();
              refreshStock();
              refreshCap();
              refreshItems();
              navigate('/order/cart');
            }}
          >
            <Badge badgeContent={cartTotalCount} color="warning">
              <ShoppingCartRoundedIcon className="" />
            </Badge>
            <span>注文へすすむ</span>
          </div>
        </React.Suspense>
      </div>
    )
  ) : (
    <div className="w-full h-full pr-4 flex flex-col">
      <div className="grow w-full flex items-center">
        <div className="w-full text-center">カートに商品が入っていません</div>
      </div>
      <div
        className="w-full m-2 py-2 rounded-full
        text-center text-2xl font-bold text-white bg-gray-300"
      >
        <Badge badgeContent={cartTotalCount} color="warning">
          <ShoppingCartRoundedIcon className="" />
        </Badge>
        <span className="ml-4">注文へすすむ</span>
      </div>
    </div>
  );
};

const CartContent = () => {
  const cartTotalCount = dataOrder.cart.useCartTotalCount();
  const cartPrice = dataOrder.cart.useCartPrice();
  const deliveryFee = dataOrder.deliveryFee.useDeliveryFee();
  const discount = dataOrder.discount.useDiscount();
  const totalPrice = cartPrice + deliveryFee + discount;

  return (
    <div className="w-full h-full flex flex-col">
      <div className="grow w-full">
        <CartItems />
      </div>
      <div className="w-full h-fit p-2 flex flex-col">
        <div className="w-full flex flex-row justify-between">
          <div className="">商品合計（{cartTotalCount}点）：</div>
          <div className="">
            <span className="text-lg">{cartPrice.toLocaleString()}</span>
            <span className="text-xs"> 円</span>
          </div>
        </div>
        <div className="w-full flex flex-row justify-between">
          <div className="">送料：</div>
          <div className="">
            <span className="text-lg">{deliveryFee.toLocaleString()}</span>
            <span className="text-xs"> 円</span>
          </div>
        </div>
        <div className="w-full flex flex-row justify-between">
          <div className="">割引：</div>
          <div className="">
            <span className="text-lg">{discount.toLocaleString()}</span>
            <span className="text-xs"> 円</span>
          </div>
        </div>
        <div className="w-full mt-1 mb-16 pt-2 flex flex-row justify-between border-t-2 border-black">
          <div className="">お支払い合計金額：</div>
          <div className="">
            <span className="text-lg font-bold">{totalPrice.toLocaleString()}</span>
            <span className="text-xs font-bold"> 円</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CartItems = () => {
  const cartItems = dataOrder.cart.useCartItemsSortedReverse();
  const addableByStock = dataOrder.cart.useCartItemsAddableCountByStock();
  const addableBySize = dataOrder.cart.useIsCartItemsAddableBySize();
  const addableByWeight = dataOrder.cart.useIsCartItemsAddableByWeight();
  const addableByCount = dataOrder.cart.useIsCartItemsAddableByCount();
  const incrementCartItem = dataOrder.cart.useIncrementCartItem();
  const decrementCartItem = dataOrder.cart.useDecrementCartItem();
  const playClickActionButtonSound = useClickActionButtonSound();

  return (
    <div className="w-full h-full flex flex-col overflow-y-auto">
      {cartItems.map((item, index) => (
        <div key={item.itemId} className="w-full p-1 pb-4  flex flex-col border-b-2">
          <div className="w-full text-xs line-clamp-1">{item.makerName}</div>
          <div className="text-lg font-bold line-clamp-2">{item.name}</div>
          <div className="w-full h-fit mt-2 pl-2 flex flex-row">
            <img alt="商品画像" src={item.image[0]} className="w-20 h-16 object-cover" />
            <div className="grow flex flex-col ml-2">
              <div className="text-right">
                <span className="text-md">{item.price.toLocaleString()}</span>
                <span className="text-xs pr-4"> 円</span>
              </div>
              <div className="mt-2 flex flex-col relative">
                <div className="text-right">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      playClickActionButtonSound();
                      decrementCartItem(item.itemId);
                    }}
                  >
                    <RemoveCircleRoundedIcon />
                  </IconButton>
                  <span className="ml-1 text-lg font-bold">{item.count}</span>
                  <span className="mr-1 text-xs"> 個</span>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      playClickActionButtonSound();
                      incrementCartItem(item.itemId);
                    }}
                    disabled={
                      !(
                        addableByStock[index] > 0 &&
                        addableBySize[index] &&
                        addableByWeight[index] &&
                        addableByCount
                      )
                    }
                  >
                    <AddCircleRoundedIcon />
                  </IconButton>
                </div>
                {/* 下記警告メッセージの位置合わせは未 (2022.7.31 aoyama) */}
                <React.Suspense fallback={<></>}>
                  <ItemMessage index={index} itemId={item.itemId} itemCount={item.count} />
                </React.Suspense>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ItemMessage = ({
  index,
  itemId,
  itemCount,
}: {
  index: number;
  itemId: string;
  itemCount: number;
}) => {
  const removeCartItem = dataOrder.cart.useRemoveCartItem();
  const availableByTodaysLunchDeadline =
    dataOrder.cart.useIsCartItemsAvailableByTodaysLunchDeadline();
  const addableByStock = dataOrder.cart.useCartItemsAddableCountByStock();
  const addableBySize = dataOrder.cart.useIsCartItemsAddableBySize();
  const addableByWeight = dataOrder.cart.useIsCartItemsAddableByWeight();
  const addableByCount = dataOrder.cart.useIsCartItemsAddableByCount();
  const playClickActionButtonSound = useClickActionButtonSound();

  return availableByTodaysLunchDeadline[index] === false ? (
    <div className="absolute bottom-1 right-1 flex flex-col bg-white">
      <div className="text-warning text-xs">
        この商品のご注文は午前11時までとさせていただいております。
      </div>
      <div className="flex flex-row justify-end">
        <IconButton
          color="warning"
          onClick={() => {
            playClickActionButtonSound();
            removeCartItem(itemId);
          }}
        >
          <BackspaceRoundedIcon />
        </IconButton>
      </div>
    </div>
  ) : addableByStock[index] < 0 ? (
    <div className="absolute bottom-6 right-1 text-right relative">
      <span
        className="absolute bottom-2 right-0 px-2 py-1 rounded
            text-warningContrast bg-warning text-xs"
      >
        残り在庫数が {addableByStock[index] + itemCount} です
        <br />
        購入数を減らしてください
      </span>
      <span
        className="absolute bottom-0 right-2 
            w-0 h-0 border border-8 border-transparent border-t-warning"
      />
    </div>
  ) : addableByStock[index] === 0 ? (
    <div className="absolute bottom-6 right-1 text-right relative">
      <span
        className="absolute bottom-2 right-0 px-2 py-1 rounded
            text-white bg-gray-400 text-xs"
      >
        在庫上限です
      </span>
      <span
        className="absolute bottom-0 right-2 
            w-0 h-0 border border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : addableBySize[index] === false ? (
    <div className="absolute bottom-6 right-1 text-right relative">
      <span
        className="absolute bottom-2 right-0 px-2 py-1 rounded
            text-white bg-gray-400 text-xs"
      >
        配達容量が上限に達しています
      </span>
      <span
        className="absolute bottom-0 right-2 
            w-0 h-0 border border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : addableByCount === false ? (
    <div className="absolute bottom-6 right-1 text-right relative">
      <span
        className="absolute bottom-2 right-0 px-2 py-1 rounded
            text-white bg-gray-400 text-xs"
      >
        配達総数が上限に達しています
      </span>
      <span
        className="absolute bottom-0 right-2
            w-0 h-0 border border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : addableByWeight[index] === false ? (
    <div className="absolute bottom-6 right-1 text-right relative">
      <span
        className="absolute bottom-2 right-0 px-2 py-1 rounded
        text-white bg-gray-400 text-xs"
      >
        配達重量が上限に達しています
      </span>
      <span
        className="absolute bottom-0 right-2 
        w-0 h-0 border border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : (
    <div></div>
  );
};
