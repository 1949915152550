import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dataShopItem } from '@common/data/ShopItem';
import { ShopItemMasterStock, dataShopItemMaster } from '@common/data/ShopItemMaster';
import { dataOrder } from '@common/data/Order';
import { ItemDialog } from './ItemDialog';
import { Cart } from './Cart';

import { useClickActionButtonSound } from '@common/data/SoundEffect';
import { CartSkeleton, CategoriesSkeleton, CategoryItemsSkeleton } from './Skeleton';
import { dataShopItemStock } from '@common/data/ShopItemStock';
import { dataShop } from '@common/data/Shop';

export const Shop = () => {
  const { categoryId } = useParams();
  // 在庫は特定時間ごとに更新
  dataShopItemStock.useShopItemStockRefreshInterval();

  return (
    <div className="flex flex-row w-screen h-screen">
      <div className="w-4/5 h-full flex flex-col py-2">
        <React.Suspense fallback={<CategoriesSkeleton></CategoriesSkeleton>}>
          <Categories />
        </React.Suspense>
        <React.Suspense fallback={<CategoryItemsSkeleton></CategoryItemsSkeleton>}>
          <CategoryItems categoryId={categoryId || ''} />
        </React.Suspense>
      </div>
      <div className="w-1/5 grow h-screen shadow-cart z-10 overflow-y-auto">
        <React.Suspense fallback={<CartSkeleton></CartSkeleton>}>
          <Cart />
        </React.Suspense>
      </div>
    </div>
  );
};

const Categories = () => {
  const categories = dataShopItem.category.useCategories();

  return (
    <div className="w-full h-fit flex overflow-x-auto">
      {categories.map((category) => (
        <CategoryName key={category.categoryId} categoryId={category.categoryId} />
      ))}
    </div>
  );
};

const CategoryName = ({ categoryId }: { categoryId: string }) => {
  const { categoryId: selectedCategoryId } = useParams();
  const navigate = useNavigate();
  const categoryName = dataShopItem.category.useCategoryName(categoryId);
  const playClickActionButtonSound = useClickActionButtonSound();
  return (
    <div
      className="px-6 py-3 text-2xl font-bold whitespace-nowrap cursor-pointer"
      onClick={() => {
        playClickActionButtonSound();
        navigate('/shop/categories/' + categoryId);
      }}
    >
      {categoryId === selectedCategoryId ? (
        <span className="text-primary">{categoryName}</span>
      ) : (
        <span>{categoryName}</span>
      )}
    </div>
  );
};

const CategoryItems = ({ categoryId }: { categoryId: string }) => {
  const navigate = useNavigate();
  const categories = dataShopItem.category.useCategories();
  React.useEffect(() => {
    if (categoryId === '') {
      navigate('/shop/categories/' + categories[0].categoryId);
    }
  }, [categoryId, navigate, categories]);
  const items = dataShopItem.category
    .useCategoryItemsInStock(categoryId)
    .concat(dataShopItem.category.useCategoryItemsSoldOut(categoryId));

  return (
    <div className="w-full h-full grid grid-rows-2 grid-flow-col gap-4 p-4 overflow-x-auto justify-start">
      {items.map((item) => (
        <ItemCard key={item.itemId} item={item} />
      ))}
    </div>
  );
};

const ItemCard = ({ item }: { item: ShopItemMasterStock }) => {
  const [openItemDialog, setOpenItemDialog] = React.useState(false);
  const [itemMaster] = dataShopItemMaster.useShopItemsMasterState();
  const selectedShop = dataShop.useShopSelected();
  const [stockMaster] = dataShopItemStock.useShopItemsStockAllState(selectedShop.shopId);
  const playClickActionButtonSound = useClickActionButtonSound();
  const addCartItem = dataOrder.cart.useAddCartItem();
  const updateItemMaster = dataShopItemMaster.useShopItemMasterUpdate();
  const updateItemStock = dataShopItemStock.useShopItemStockUpdate();
  const addCart = () => {
    playClickActionButtonSound();
    console.log('add cart');
    addCartItem(item.itemId, 1).then((ret) => {
      //if (!ret) // 追加できなかった
    });
    setOpenItemDialog(false);
  };

  return (
    <div className="w-64 h-full flex flex-col relative rounded-xl border-2">
      <ItemDialog
        open={openItemDialog}
        itemId={item.itemId}
        callbackOk={addCart}
        callbackCancel={() => {
          playClickActionButtonSound();
          setOpenItemDialog(false);
        }}
      />
      <div
        className="w-full h-full cursor-pointer"
        onClick={() => {
          updateItemMaster(item.itemId, itemMaster);
          updateItemStock(item.itemId, stockMaster);
          playClickActionButtonSound();
          setOpenItemDialog(true);
        }}
      >
        <div className="w-full h-2/3 relative">
          <img
            alt="商品画像"
            src={item.image[0]}
            className="h-full w-full object-cover rounded-t-md"
          />
          {item.stockCount > 0 && item.stockCount <= (item.limitedStock ?? 1) && (
            <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-warning/75">
              <div className="font-bold">残り僅か</div>
            </div>
          )}
          {item.stockCount <= 0 && (
            <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-black/75">
              <div className="font-bold">売り切れ</div>
            </div>
          )}
        </div>
        <div className="mx-2 my-1 text-xs font-bold text-primary-light line-clamp-1">
          {item.makerName}
        </div>
        <div className="mx-2 grow text-md font-bold line-clamp-2">{item.name}</div>
        <div className="mx-2 my-1 text-right">
          <span className="text-lg">{item.price.toLocaleString()}</span>
          <span className="text-xs"> 円</span>
        </div>
      </div>
    </div>
  );
};
