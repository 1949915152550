import { Skeleton } from '@mui/material';

export const CategoryItemsSkeleton = () => {
  return (
    <div className="w-full h-full  flex flex-row">
      <div className="w-60 h-1/2 flex flex-col py-1 m-5">
        <div className="w-full h-4/5 ">
          <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
        </div>
        <div>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'50%'} />
        </div>
        <div className="w-full h-1/5 grow flex items-center justify-end">
          <Skeleton variant="rounded" animation="wave" width="40%" height="40%" />
        </div>
      </div>
    </div>
  );
};

export const CategoriesSkeleton = () => {
  return (
    <div className="w-full h-1/5">
      <div className="w-full h-full">
        <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
      </div>
    </div>
  );
};

export const CartSkeleton = () => {
  return (
    <div className="w-full h-full">
      <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
    </div>
  );
};

export const CartItemsSkeleton = () => {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto">
      <div className=" w-full h-1/5 m-1 items-center justify-center">
        <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
      </div>
    </div>
  );
};

export const CartPriceSkeleton = () => {
  return (
    <div className=" w-2/3 h-2/5 m-2">
      <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
    </div>
  );
};

export const ItemDetailsSkeleton = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row">
        <div className="w-1/2 pr-2">
          <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
        </div>
        <div className="w-1/2 flex flex-col">
          <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
        </div>
      </div>
      <div>
        <hr className="my-4" />
        <div className="flex flex-row items-center">
          <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
        </div>
      </div>

      <div>
        <hr className="my-4" />

        <div className="flex ml-4">
          <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
        </div>

        <div>
          <hr className="my-4" />

          <div className="flex flex-row items-center">
            <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};
