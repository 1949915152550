import clickActionButtonSound from '@common/assets/sound/clickActionButtonSound.mp3';
import clickNavigationButtonSound from '@common/assets/sound/clickNavigationButtonSound.mp3';
import RingToneSound from '@common/assets/sound/chime00.mp3';
import useSound from 'use-sound';

export const useClickActionButtonSound = () => {
  const [playClickSound] = useSound(clickActionButtonSound, { volume: 0.7 });

  return playClickSound;
};

export const useClickNavigationButtonSound = () => {
  const [playClickSound] = useSound(clickNavigationButtonSound, { volume: 0.7 });

  return playClickSound;
};

export const useRingToneSound = () => {
  const [playSound] = useSound(RingToneSound, { volume: 0.7 });
  return playSound;
};
