// import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClickNavigationButtonSound } from '@common/data/SoundEffect';
import { useFetchFailedMessage } from '@common/data/ApiOrder';

export const OrderFailed = () => {
  // ブラウザバックを無効化
  window.history.pushState(null, '', window.location.href);

  const { orderId } = useParams();

  const navigate = useNavigate();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();

  const failedMessage = useFetchFailedMessage(orderId ?? '');

  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="w-full h-4/5 flex flex-col items-center justify-center">
        <div className="text-6xl font-extrabold">お支払いに失敗しました</div>
        <p className="pt-8 text-6xl leading-relaxed whitespace-pre-wrap">{failedMessage}</p>
      </div>
      <div className="w-full grow flex items-center justify-center">
        <div
          className="w-1/2 py-3 rounded-full cursor-pointer 
          text-center text-4xl font-bold text-white bg-primary"
          onClick={() => {
            playClickNavigationButtonSound();
            navigate('/');
          }}
        >
          トップ画面へ戻る
        </div>
      </div>
    </div>
  );
};
