import { Button, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { userActions } from '@common/data/User';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DialogOk } from '@common/components/Dialog';

type FormType = {
  surName: string;
  givenName: string;
  phone: string;
  place: string;
};

type Props = {
  title: string;
  btnText: string;
  navigateUrl?: string;
};

// TODO: commonに切り出す
export const UserForm = ({ title, btnText, navigateUrl }: Props) => {
  const user = userActions.useGetUser();
  const saveUserFunc = userActions.useStoreUser();
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const { handleSubmit, control, reset, watch } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      surName: '',
      givenName: '',
      phone: '',
      place: '',
    },
  });
  // formの値の監視
  const allWatch = watch();

  useEffect(() => {
    reset({
      surName: user.surName,
      givenName: user.givenName,
      phone: user.phone,
      place: user.place,
    });
  }, [user, reset]);

  useEffect(() => {
    if (
      allWatch.surName === user.surName &&
      allWatch.givenName === user.givenName &&
      allWatch.phone === user.phone &&
      allWatch.place === user.place
    ) {
      setDisabledBtn(true);
      return;
    } else {
      setDisabledBtn(false);
    }
  }, [allWatch, user]);

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    if (processing) return;

    setProcessing(true);
    await saveUserFunc({ ...user, ...data, phone: data.phone.replace(/-/g, '') });
    setProcessing(false);

    if (navigateUrl) {
      // MEMO: React18のレンダリング制御により、下記のようにしないとnavigateが
      // 想定通りに動作しない
      setTimeout(() => {
        navigate(navigateUrl);
      });
      return;
    }

    setOpen(true);
  };

  return (
    <>
      <DialogOk
        open={open}
        message="変更を登録しました"
        callbackOk={() => setOpen(false)}
        callbackOnClose={() => setOpen(false)}
      />

      <Stack spacing={2} alignItems={'center'} className="p-5">
        <Typography variant="h5" className="text-center">
          {title}
        </Typography>
        <Paper className="w-full sm:w-[500px]">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-4 w-4/5 mx-auto my-2"
          >
            <Controller
              name="surName"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="姓"
                  variant="standard"
                  size="small"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="givenName"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="名"
                  variant="standard"
                  size="small"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
                pattern: {
                  value: /^0\d{1}0-{0,1}\d{4}-{0,1}\d{4}$/,
                  message: '有効な携帯電話番号ではありません',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="携帯電話番号"
                  variant="standard"
                  size="small"
                  className="w-full"
                  type="tel"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />

            {/* MEMO: order_appのみ設定する項目 */}
            <Controller
              name="place"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="販売機設置場所"
                  variant="standard"
                  size="small"
                  className="w-full"
                  type="tel"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            {processing ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" type="submit" disabled={disabledBtn}>
                {btnText}
              </Button>
            )}
          </form>
        </Paper>
      </Stack>
    </>
  );
};
