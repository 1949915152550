import { useClickActionButtonSound, useClickNavigationButtonSound } from '@common/data/SoundEffect';
import { useNavigate } from 'react-router-dom';
import { OrderStepper } from './OrderStepper';
import { ReactComponent as RemoveButton } from '@common/assets/_i_icon_11909_icon_119090_256.svg';
import { useEffect, useState } from 'react';
import { dataOrder } from '@common/data/Order';

export const PhoneNumberForm = () => {
  const navigate = useNavigate();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();
  const playClickActionButtonSound = useClickActionButtonSound();
  const updatePhoneNumberCallback = dataOrder.phoneNumber.useSetOrderPhoneNumber();
  const [phoneNumber, setPhoneNumber] = useState(dataOrder.phoneNumber.useOrderPhoneNumberState());
  const [isNextDisable, setIsNextDisable] = useState(true);

  useEffect(() => {
    if (phoneNumber.match(/^0\d{1}0-{0,1}\d{4}-{0,1}\d{4}$/)) {
      setIsNextDisable(false);
      return;
    }
    setIsNextDisable(true);
  }, [phoneNumber]);

  const phoneNumberInputCallback = (value: string) => {
    playClickActionButtonSound();
    if (phoneNumber.length === 3 || phoneNumber.length === 8) {
      setPhoneNumber(phoneNumber + '-' + value);
      return;
    }
    setPhoneNumber(phoneNumber + value);
  };

  const phoneNumberRemoveCallback = () => {
    playClickActionButtonSound();
    if (phoneNumber.length === 5 || phoneNumber.length === 10) {
      setPhoneNumber(phoneNumber.slice(0, -2));
      return;
    }
    setPhoneNumber(phoneNumber.slice(0, -1));
  };

  return (
    <div style={{ backgroundColor: 'rgb(251,240,221)' }}>
      <div className="w-1/2 mx-auto mb-2">
        <OrderStepper activeStep={1} />
      </div>
      <div className="flex flex-col items-center">
        <div className="mb-2 text-4xl font-bold text-center">携帯電話番号入力</div>
        <div className="mb-4 text-2xl text-md text-center">
          商品到着時に、SMSにて通知をお送りする携帯電話番号の入力をお願い致します。
          <br />
          また、お電話にてご連絡させて頂く場合もございます。
        </div>
        <div className="bg-white py-2 px-16 text-3xl rounded-xl w-1/3 h-[80px] flex justify-center items-center">
          {phoneNumber}
        </div>
        <div className="my-4 grid grid-cols-3 grid-rows-4 gap-4">
          <PhoneNumberInputButton number={1} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={2} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={3} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={4} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={5} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={6} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={7} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={8} phoneNumberInputCallback={phoneNumberInputCallback} />
          <PhoneNumberInputButton number={9} phoneNumberInputCallback={phoneNumberInputCallback} />
          <div />
          <PhoneNumberInputButton number={0} phoneNumberInputCallback={phoneNumberInputCallback} />
          <RemoveButton className="cursor-pointer" onClick={phoneNumberRemoveCallback} />
        </div>
      </div>
      <footer className="fixed bottom-0 left-0 w-full bg-white p-2">
        <div className="grid grid-cols-3 gap-4 items-end">
          {
            <>
              <div
                className="col-start-1 col-end-1 p-3 rounded-full cursor-pointer
          text-center text-2xl font-bold text-primary border-primary border-4"
                onClick={() => {
                  playClickNavigationButtonSound();
                  navigate('/order/cart');
                }}
              >
                戻る
              </div>
              {isNextDisable ? (
                <div
                  className="col-start-3 col-end-3 p-4 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-white bg-warning"
                >
                  携帯電話番号を入力してください
                </div>
              ) : (
                <div
                  className="col-start-3 col-end-3 p-4 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-white bg-primary"
                  onClick={() => {
                    playClickNavigationButtonSound();
                    updatePhoneNumberCallback(phoneNumber);
                    navigate('/order/confirm');
                  }}
                >
                  次へ
                </div>
              )}
            </>
          }
        </div>
      </footer>
    </div>
  );
};

const PhoneNumberInputButton = ({
  number,
  phoneNumberInputCallback,
}: {
  number: number;
  phoneNumberInputCallback: (value: string) => void;
}) => {
  return (
    <button
      className="bg-primary text-2xl px-8 py-4 rounded-full text-primaryContrast cursor-pointer active:bg-gray-500"
      onClick={() => phoneNumberInputCallback(number.toString())}
    >
      {number}
    </button>
  );
};
