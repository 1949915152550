import React, { useEffect, useState } from 'react';
import { dataOrder } from '@common/data/Order';

// MUI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { ItemDetailsSkeleton } from './Skeleton';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';

type SelectDeliveryTimeDialogProps = {
  readonly open: boolean;
  readonly currentIndex: number;
  readonly setIndex: (index: number) => void;
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
};
export const SelectDeliveryTimeDialog = ({
  open,
  currentIndex,
  setIndex,
  callbackOk,
  callbackCancel,
}: SelectDeliveryTimeDialogProps) => {
  const deliveryTimeSlotOptions = dataOrder.deliveryTime.useDeliveryTimeOptionsOfSelectedDate();
  const [select, setSelect] = useState(currentIndex);

  useEffect(() => {
    setIndex(select);
  }, [select, deliveryTimeSlotOptions]);

  return (
    <Dialog open={open} onClose={callbackCancel} fullWidth maxWidth="xl">
      <DialogContent>
        <React.Suspense fallback={<ItemDetailsSkeleton></ItemDetailsSkeleton>}>
          <div className="m-4">
            <Grid container spacing={1}>
              {deliveryTimeSlotOptions.map((slot, index) => (
                <React.Fragment key={index}>
                  {/* {slot.firstSlotOfTheDay && 
                  <Grid item xs={12} justifyContent="center" className="flex items-end">
                    <span className="text-xl">
                      {slot.begin.getMonth()+1} / {slot.begin.getDate()}
                    </span>
                    {isSameDate(slot.begin, today) && <span>（今日）</span>}
                  </Grid>
                } */}
                  <Grid item xs={6} sm={3}>
                    <Button
                      color="primary"
                      disableElevation
                      className="w-full"
                      variant={select === index ? 'contained' : 'outlined'}
                      onClick={() => setSelect(index)}
                      disabled={slot.available <= 0}
                    >
                      {slot.toStringTime(' ～ ')}
                      {' (' + slot.available + ')'}
                    </Button>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </React.Suspense>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <DialogButton callbackOk={callbackOk} callbackCancel={callbackCancel} />
      </DialogActions>
    </Dialog>
  );
};

type DialogButtonProps = {
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
};
const DialogButton = ({ callbackOk, callbackCancel }: DialogButtonProps) => {
  return (
    <div className="w-full h-fit flex flex-row">
      <div
        className="w-1/3 py-2 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-4 mr-4"
        onClick={callbackCancel}
      >
        戻る
      </div>
      <div
        className="w-2/3 py-3 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-white bg-primary"
        onClick={callbackOk}
      >
        OK
      </div>
    </div>
  );
};
