// import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useClickNavigationButtonSound } from '@common/data/SoundEffect';

export const OrderCanceled = () => {
  // ブラウザバックを無効化
  window.history.pushState(null, '', window.location.href);

  const navigate = useNavigate();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();

  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="w-full h-4/5 flex items-center justify-center">
        <div className="text-6xl">お支払いがキャンセルされました</div>
      </div>
      <div className="w-full grow flex items-center justify-center">
        <div
          className="w-1/2 py-3 rounded-full cursor-pointer 
          text-center text-4xl font-bold text-white bg-primary"
          onClick={() => {
            playClickNavigationButtonSound();
            navigate('/');
          }}
        >
          トップ画面へ戻る
        </div>
      </div>
    </div>
  );
};
