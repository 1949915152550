import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dataOrder, OrderItemState } from '@common/data/Order';
import { useClickNavigationButtonSound } from '@common/data/SoundEffect';
import CircularProgress from '@mui/material/CircularProgress';
import { OrderStepper } from './OrderStepper';

/*
  Windows環境C#での印刷機能提供I/Fのため消さないこと
  ここから
*/
export interface CustomWindow extends Window {
  orderSucceeded: (order: string) => Promise<void>;
}
declare let window: CustomWindow;
/*
  ここまで
  Windows環境C#での印刷機能提供I/Fのため消さないこと
*/

export function OrderCompleted() {
  // ブラウザバックを無効化
  window.history.pushState(null, '', window.location.href);
  const { orderId } = useParams();
  if (!orderId) throw new Error('Routing Error.');
  const order = dataOrder.history.useOrderItem(orderId);

  dataOrder.deliveryTime.useDeliveryTimeOptions();
  dataOrder.cart.useCartItems();
  const clearCart = dataOrder.cart.useClearCart();

  useEffect(() => {
    const clear = async () => {
      await clearCart();
    };
    clear();
  }, []);

  return (
    <div>
      {order ? (
        <OrderCompletedDetail order={order} />
      ) : (
        <div>
          <div className="text-center pt-2">ご注文を確認しています</div>
          <div className="w-full h-screen flex justify-center items-center">
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
}

export const OrderCompletedDetail = ({ order }: { order: OrderItemState }) => {
  const playClickNavigationButtonSound = useClickNavigationButtonSound();
  const navigate = useNavigate();

  const deliveryDate = order?.deliveryTime?.toStringDate();
  const deliveryTime = order?.deliveryTime?.toStringTime('-');

  /*
  Windows環境C#での印刷機能提供I/Fのため消さないこと
  ここから
  */
  useEffect(() => {
    const jsonOrder = JSON.stringify(order);
    console.log('jsonOrder?', jsonOrder);
    window.orderSucceeded(jsonOrder);
  }, []);
  /*
  ここまで
  Windows環境C#での印刷機能提供I/Fのため消さないこと
  */

  return (
    <section className="p-6" style={{ backgroundColor: 'rgb(251,240,221)' }}>
      <div className="w-1/2 mx-auto mb-2">
        <OrderStepper activeStep={4} />
      </div>
      <div className="my-6 text-4xl font-bold text-center">ご注文完了</div>
      <div className="mb-6 text-xl text-center">
        ありがとうございます。ご注文を受け付けました。
        <br />
        お受取可能に受付番号が表示されましたら、印刷されたレシートのQRコードを
        <br />
        受け取りロッカーにかざして商品をお受け取りください。
      </div>
      <div></div>

      <div className="grid grid-cols-2 gap-8 w-4/5 mx-auto">
        <div className="">
          <div className="mb-2 text-xl font-bold">受付番号</div>
          <div className="rounded-2xl bg-white p-3">
            <div className="text-center font-bold justify-center">
              <div className="text-4xl">{order?.receiptNumber}</div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mb-2 text-xl font-bold">お届け予定時刻</div>
          <div className="rounded-2xl bg-white p-3">
            <div className="text-center justify-center">
              <span className="mr-2">{deliveryDate}</span>
              <span className="font-bold text-4xl">{deliveryTime}</span>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mb-2 text-xl font-bold">お支払い</div>
          <div className="rounded-2xl bg-white p-3">
            <div className="text-center justify-center">
              <div className="justify-center">
                <div>
                  <span className="mr-2">お支払い合計金額</span>
                  <span className="font-bold text-4xl">
                    ¥{Number(order?.totalPrice).toLocaleString()}
                  </span>
                </div>
                <div>
                  <span className="text-sm">決済方法</span>
                  <span className="font-bold ml-2">{order.payMethod}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mb-2 text-xl font-bold">お届け先</div>
          <div className="rounded-2xl bg-white p-3">
            <div className="text-center font-bold justify-center">
              <div className="text-2xl">{order?.deliveryTo}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-10 text-4xl font-bold text-center">
        右下の発券口からレシートをお受け取り下さい
      </div>
      <div>
        <div className="mt-12 w-full grow flex items-center justify-center">
          <div
            className="w-1/2 py-3 rounded-full cursor-pointer 
          text-center text-4xl font-bold text-white bg-primary"
            onClick={() => {
              playClickNavigationButtonSound();
              navigate('/');
            }}
          >
            トップ画面へ戻る
          </div>
        </div>
      </div>
    </section>
  );
};
