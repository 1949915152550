import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { dataShop } from '@common/data/Shop';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ShopSelect = () => {
  const shops = dataShop.useShopsValue();
  const selectedShop = dataShop.useShopSelected();
  const [, setSelectedSpotId] = dataDeliveryTo.useSpotSelectedIdState();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      <p className="mb-2 ml-2 text-2xl">店舗を選択してください</p>
      <div className="flex flex-col gap-2 px-4">
        {shops.map((shop) => {
          return (
            <Button
              key={shop.shopId}
              variant="contained"
              disabled={shop.shopId === selectedShop.shopId}
              onClick={() => {
                setSelectedSpotId(shop.spotId[0]);
              }}
            >
              <p key={shop.shopId} className="text-xl">
                {shop.shopName}
              </p>
            </Button>
          );
        })}
      </div>
      <div
        className="w-1/3 mt-8 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-4 mr-4"
        onClick={() => navigate('/AdministratorMenu')}
      >
        管理者メニューに戻る
      </div>
    </div>
  );
};
