

export const TimeoutChecker = () => {

  let lastOperation = Date.now()

  const intervalId = setInterval(() => {
    // タイムアウト時間 (5分)
    const TIMEOUT_MSEC = 5 * 60 * 1000;
    const now = Date.now();

    // タイムアウト判定
    const isTimeout = now - lastOperation > TIMEOUT_MSEC;

    // タイムアウト判定結果を表示
    if (isTimeout) {
      console.warn('一定時間操作がありませんでした');
      window.location.href = '/order/timeout';
      clearInterval(intervalId);
    }
    // console.log('[CHECK_TIMER] ', intervalId, now - lastOperation);
  }, 1000);

  // 最後に操作した時間を記録する
  const watchEventTypes =
    [
      'click', // マウスの左ボタンをクリック
      'touchstart', // タッチ開始（スマホ）
    ]
  watchEventTypes.map((type) => {
    return window.addEventListener(type, () => {
      const now = Date.now();
      lastOperation = now;
      // console.log('[updateLastOperation] ', now);
    });
  });

};
